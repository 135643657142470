import React from 'react';
// import { graphql } from 'gatsby';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Layout from '../../layouts';
import Checkout from '../../components/Checkout';

const page = { name: 'Checkout', slug: 'checkout' };
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);

export default function CheckoutPage(props) {
  const { location } = props;
  const prevPage = location.state && location.state.prevPage;
  return (
    <Layout
      title={page.name}
      location={`/${page.slug}/`}
      prevPage={prevPage}
      cart
    >
      <Elements stripe={stripePromise}>
        <Checkout /* {...props} */ />
      </Elements>
    </Layout>
  );
}

/* export const CheckoutData = graphql`
  query CheckoutQuery {
    wp {
      shippingOptions {
        name
        price
        minAmount
        methodId
      }
    }
  }
`; */
